/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */
 try {
    window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');
   
    require('bootstrap');
} catch (e) {};

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

require('./core/jquery.min');
require('./core/popper.min'); 
require('./core/bootstrap-material-design.min'); 
window.PerfectScrollbar =  require('./plugins/perfect-scrollbar.min'); 
require('./plugins/sweetalert2');
require('./plugins/jquery.validate.min');
require('./plugins/jquery.bootstrap-wizard');
require('./plugins/bootstrap-selectpicker');
require('./plugins/bootstrap-datetimepicker.min');
require('./plugins/jquery.dataTables.min');
require('./plugins/bootstrap-tagsinput');
require('./plugins/jasny-bootstrap.min');
require('./plugins/fullcalendar.min');
require('./plugins/nouislider.min');
require('./plugins/arrive.min');
require('./plugins/chartist.min');
require('./plugins/bootstrap-notify');

require('./material-dashboard');
